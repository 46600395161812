export default {
  data: () => ({
    keyPref: null,
  }),
  computed: {
    /**
     * The key path of the component save preferences on preference object
     */
    preferencesPath() {
      return `tablePreferences-${this.keyPref}`;
    },
    /**
     * Get userPreferences from Auth store
     *
     * @return {Record<string, unknown>} Return object of userPreferences
     */
    userPreferences() {
      return this.$store.state.auth.currentUserffly4u[this.preferencesPath] || {};
    },
  },
  created() {
    // fetch the path of the current page.
    // Ex: for ffly4u.com/list/users => keyPref = /list/users
    let currentPath = this.$router.currentRoute.matched[0].path;
    if (currentPath.length === 0) {
      currentPath = 'home';
    }
    this.keyPref = currentPath;
  },
  methods: {
    /**
     * Update the preferences of List
     * Merge new preferences with previous
     *
     * @param {Object} preferences object of the page
     */
    updatePrefs(preferences) {
      this.$store.dispatch('auth/SET_SELF', {
        path: this.preferencesPath,
        value: preferences,
      });
    },
  },
};
