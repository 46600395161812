<template>
  <v-icon
    small
    class="action"
    :title="title"
    @click.stop="setReadStatus(!isRead)"
  >
    {{ icon }}
  </v-icon>
</template>
<script>
/**
 * Button to toggle readStatus
 */
export default {
  name: 'SwitchRead',
  props: {
    isRead: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    /**
     * Toggle title
     *
     * @return {string} Return the title of button translated
     */
    title() {
      return this.isRead
        ? this.$t('misc.setUnread')
        : this.$t('misc.setRead');
    },
    /**
     * Toggle icon
     *
     * @return {string} Return name of icon
     */
    icon() {
      return this.isRead
        ? 'mdi-email'
        : 'mdi-email-open';
    },
  },
  methods: {
    /**
     * Emit new ReadStatus to parent
     *
     * @param {boolean} status state of readStatus
     */
    setReadStatus(status) {
      this.$emit('change', status);
    },
  },
};
</script>
