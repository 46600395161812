<template>
  <v-menu
    v-model="open"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-x
  >
    <template v-slot:activator="{ on }">
      <v-icon
        @click.stop="on.click"
        small
        class="action"
      >
        mdi-delete
      </v-icon>
    </template>
    <v-card>
      <v-card-text>
        {{ $t('misc.areYouSureToDelete') }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="confirm"
          color="error"
          small
        >
          {{ $t('misc.yes') }}
        </v-btn>
        <v-btn
          @click="open = false"
          small
        >
          {{ $t('misc.no') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
/**
 * Button to confirm deletion
 * Use a "v-menu" to open a "v-card" popup with confirm action (yes/No)
 */
export default {
  name: 'ButtonConfirm',
  data: () => ({
    open: false,
  }),
  watch: {
    /**
     * When state of "open" change, emit the new state to parent.
     *
     * @param {boolean} value open status
     */
    open(value) {
      this.$emit('open', value);
    },
  },
  methods: {
    /**
     * When deletion is confirmed, close the popup
     * and emit "confirm" event to parent
     */
    confirm() {
      this.open = false;
      this.$emit('confirm');
    },
  },
};
</script>
