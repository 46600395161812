<template>
  <v-flex :class="{ 'striped': stripedTables }">
    <v-layout align-center justify-center row fill-height v-if="searchable">
      <v-flex xs12>
        <v-text-field
          class="search"
          v-on:input="updateSearch"
          :label="$t(searchBarTitle)"
          append-icon="mdi-magnify"
          solo
          v-model="currentSearch"
          v-on:click:append="updateSearch"
        >
          <template slot="append-outer">
            <slot name="filter"></slot>
          </template>
        </v-text-field>
      </v-flex>
    </v-layout>
    <v-layout align-start justify-center row fill-height>
      <v-flex xs12>
        <v-data-table
          v-model="currentSelected"
          :headers="headers"
          :items="computedHits"
          class="elevation-1"
          item-key="id"
          :server-items-length="total"
          :loading="loading"
          :options.sync="options"
          :show-select="selectable"
          :hide-default-header="$vuetify.breakpoint.smAndDown"
          :fixed-header="true"
          :height="innerHeight"
          :footer-props="{
            'items-per-page-options': [5,10,15,50,100,500,1000,5000,10000]
          }"
        >
          <template v-slot:item="{ item }">
            <tr v-on:click="click(item._data)" :class="classRow(item)">
              <td
                v-for="(column, index) in item"
                :key="index"
              >
                <div class="table-actions" v-if="column.format.type === 'actions'">
                  <a
                    v-if="(updatable || readable) && isExternalLink"
                    :href="`${itemRoute}/${column.value}`"
                    class="action"
                  >
                    <v-icon
                      small
                      v-if="updatable"
                      :title="$t('misc.edit')"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                      small
                      v-else
                      :title="$t('misc.view')"
                    >
                      mdi-eye
                    </v-icon>
                  </a>
                  <router-link
                    v-else-if="updatable || readable"
                    :to="{ path: getItemRoute(column.value) }"
                    class="action"
                  >
                    <v-icon
                      small
                      v-if="updatable"
                      :title="$t('misc.edit')"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                      small
                      v-else
                      :title="$t('misc.view')"
                    >
                      mdi-eye
                    </v-icon>
                  </router-link>
                  <button-confirm
                    :key="index"
                    v-if="deletable"
                    @open="focus(item._data, $event)"
                    @confirm="deleteData(item._data.id)"
                  />
                  <switch-read
                    v-if="readStatus"
                    :isRead="item._isRead"
                    @change="setReadStatus(item._data, $event)"
                  />
                </div>
                <format-data
                  v-else
                  :value="column.value"
                  :format="column.format"
                />
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
import tableSearchPreferences from '@/mixins/tableSearchPreferences';
import { createCsvLabels, createCsvContent } from '@/misc/utils';
import { mapGetters } from 'vuex';
import _ from '../../misc/lodash';
import dataAccess from '../../misc/dataAccess';
import ButtonConfirm from './ButtonConfirm.vue';
import SwitchRead from './SwitchRead.vue';
import FormatData from './FormatData.vue';

/**
 * Interface to v-data-table with search text field
 */
export default {
  name: 'Table',
  mixins: [tableSearchPreferences],
  components: {
    ButtonConfirm,
    SwitchRead,
    FormatData,
  },
  props: {
    object: {
      type: String,
    },
    alias: {
      type: String,
    },
    plugin: {
      type: String,
    },
    itemRoute: {
      type: String,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    hits: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Array,
      default: () => [],
    },
    focused: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: '',
    },
    pagination: {
      type: Object,
      default: () => ({ page: 1, perPage: 15 }),
    },
    total: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    readable: {
      type: Boolean,
      default: false,
    },
    updatable: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
    readStatus: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
    },
    queryParams: {
      type: Object,
      default: () => {},
    },
    searchBarTitle: {
      type: String,
      default: 'crud.search',
    },
  },
  data: () => ({
    currentFocused: [],
    currentSelected: [],
    currentSearch: '',
    options: {},
  }),
  computed: {
    ...mapGetters('settings', ['getItemsPerPage', 'stripedTables']),
    /**
     * Compute the hits
     * Prepare data for actions, add readStatus and add raw data
     *
     * @return {Record<string, unknown>[]} Return array of computed hits
     */
    computedHits() {
      return this.hits.map((row) => {
        const dataRow = this.headers.map((column) => {
          const format = { ...column.format };
          // Support label for link
          if (typeof format.type !== 'undefined' && format.type.indexOf('link') !== -1) {
            format.label = column.label;
          }

          return {
            value: dataAccess.get(row, column.value),
            format,
          };
        });
        dataRow._isFocused = this.currentFocused.indexOf(row.id) !== -1;

        // ReadStatus it's reactive and computed can tracked the update
        const readStatus = row['user-read'] || [];

        dataRow._isRead = this.isRead(readStatus);
        dataRow._data = row;
        return dataRow;
      });
    },
    /**
     * Compute state of pagination with page and perPage
     *
     * @return {Record<string, number>} Return state of pagination
     */
    locatPagination() {
      return {
        itemsPerPage: this.options.itemsPerPage,
        page: this.options.page,
      };
    },
    /**
     * Compute state of sort to normalize output
     *
     * @return {Record<string, unknown>[]} Return state of sort
     */
    localSort() {
      return (this.options.sortBy || []).reduce((acc, sortPath, index) => {
        const header = this.headers.find(({ path }) => _.isEqual(path, sortPath));
        const sortKey = (header.sortable !== true && header.sortable) || sortPath;
        const order = this.options.sortDesc[index] === false ? 'DESC' : 'ASC';

        const sort = typeof sortKey === 'object' && typeof sortKey.fields !== 'undefined'
          ? sortKey.fields.reduce((fields, path) => ({ ...fields, [path]: order }), {})
          : { [sortKey]: order };
        return {
          ...acc,
          ...sort,
        };
      }, {});
    },
    /**
     * Compute inner height of component
     *
     * @return {number} Return inner height of component
     */
    innerHeight() {
      if (this.$route.path.includes('item/business-events')) {
        const height = !Number.isNaN(this.height) ? this.height - 129 : 279;
        return Math.max(height, 279);
      }
      if (this.keyPref === 'home') {
        const height = !Number.isNaN(this.height) ? this.height - 149 : 279;
        return Math.max(height, 279);
      }
      // Prevent NaN height and compute innerHeight (height minus height of footer)
      return !Number.isNaN(this.height) ? this.height - 59 : 279;
    },
    /**
     * Checks if itemRoute point to an external link or not
     */
    isExternalLink() {
      return this.itemRoute?.includes('http') || this.itemRoute?.includes('https');
    },
  },
  watch: {
    /**
     * Update the currentSearch if is selectable
     *
     * @param {string} value String of search
     */
    search(value) {
      // Update only is selectable
      if (this.searchable) {
        this.currentSearch = value;
      }
    },
    /**
     * Update pagination data in options
     *
     * @param {Record<string, unknown>} value
     */
    pagination(value) {
      this.options = { ...this.options, ...value };
      this.$store.commit('settings/SET_ITEMS_PER_PAGE', value.itemsPerPage);
      if (this.keyPref === 'home') {
        this.updatePrefs({ ...this.userPreferences, pagination: this.pagination });
      }
    },
    /**
     * Emit the options when are updated by v-data-table (options.sync)
     */
    options: {
      handler() {
        this.$emit('options', this.options);
      },
      deep: true,
    },
    /**
     * Update state currentFocused when props focused change
     *
     * @param {Record<string, Record<string, unknown>>} focused Object of hit
     */
    focused(focused) {
      this.currentFocused = focused;
    },
    /**
     * Emit id of selected hits
     */
    currentSelected() {
      this.$emit('update:selected', this.currentSelected.map((sel) => ({ id: sel.id })));
    },
    /**
     * Emit pagination state
     */
    locatPagination(current, previous) {
      if (!_.isEqual(previous, current)) {
        this.$emit('update:pagination', current);
      }
    },
    /**
     * Emit sort state
     */
    localSort(current, previous) {
      if (!_.isEqual(previous, current)) {
        this.$emit('update:sort', current);
      }
    },
    /**
     * Generate csvData when table data change and emits an update:csdData event
     */
    async computedHits() {
      let dataArray = this.computedHits;

      const labels = this.headers.reduce((acc, el) => {
        if (el.format?.type !== 'actions') {
          return [...acc, el.text];
        }
        return acc;
      }, []);
      dataArray = await Promise.all(dataArray.map(async (hit) => {
        const newHit = {};
        for (let index = 0; index < hit.length; index += 1) {
          const element = hit[index];
          if (element?.format?.type !== 'actions') {
            // could be done using promise all instead of using await in loop
            // but this one seems faster than the version with Promise all because of two loop there
            // eslint-disable-next-line no-await-in-loop
            const { value } = await this.formatContent(element.value, element.format);
            element.text = value;
            newHit[this.headers[index].text] = value;
          }
        }
        return newHit;
      }));

      let csv = createCsvLabels(labels, ',');
      csv = `${csv}${createCsvContent(dataArray, labels, ',')}`;
      this.$emit('update:csvData', csv);
    },
  },
  /**
   * Init data on created
   */
  created() {
    // fetch the path of the current page.
    // Ex: for ffly4u.com/list/users => keyPref = /list/users
    let currentPath = this.$router.currentRoute.matched[0].path;
    if (currentPath.length === 0) {
      currentPath = 'home';
    }
    this.keyPref = currentPath;
    const prefs = this.userPreferences;
    if (this.keyPref === 'home' && prefs && prefs.pagination) {
      // Set options for table
      if (prefs.pagination.itemsPerPage && this.pagination.perPage) {
        this.options = { ...prefs.pagination, perPage: prefs.pagination.itemsPerPage };
      } else if (prefs.itemsPerPage && this.pagination.itemsPerPage) {
        this.options = { ...prefs.pagination, itemsPerPage: prefs.pagination.itemsPerPage };
      } else {
        this.options = prefs.pagination || this.pagination;
      }
    } else if (this.getItemsPerPage && this.pagination.perPage) { // Set options for table
      this.options = { ...this.pagination, perPage: this.getItemsPerPage };
    } else if (this.getItemsPerPage && this.pagination.itemsPerPage) {
      this.options = { ...this.pagination, itemsPerPage: this.getItemsPerPage };
    } else {
      this.options = this.pagination;
    }

    // Set current selected row only if is selectable and not empty
    if (this.selectable && this.selected.length > 0) {
      this.currentSelected = this.selected;
    }
    // Set current search only if searchable and not empty
    if (this.searchable && this.search.length > 0) {
      this.currentSearch = this.search;
    } else if (this.userPreferences
      && this.userPreferences.search && this.userPreferences.search.length > 0) {
      this.currentSearch = this.userPreferences.search;
    }
  },
  methods: {
    /**
     * Update Search
     */
    updateSearch() {
      this.$emit('update:search', this.currentSearch);
      const prefs = { ...this.userPreferences, search: this.currentSearch };
      if (prefs.pagination && prefs.pagination.page) {
        prefs.pagination.page = 1;
      }
      this.updatePrefs(prefs);
    },
    /**
     * Build item route
     *
     * @param {Int} id id of item
     */
    getItemRoute(id) {
      if (this.itemRoute && this.itemRoute.endsWith('=')) {
        if (id === 'None') {
          // return this.itemRoute.split('?')[0];
          return `${this.itemRoute}HORS_SITE`;
        }
        return `${this.itemRoute}${id}`;
      }
      if (this.itemRoute) {
        return `${this.itemRoute}/${id}`;
      }
      return `/item/${this.alias || this.object}/${id}`;
    },
    /**
     * Delete an item and emits refreshData event
     *
     * @param {Int} id id of item
     */
    deleteData(id) {
      const { level } = this.queryParams;
      const params = {};
      if (level) {
        params.level = level;
      }
      this.$store.dispatch('crud/DELETE', {
        object: this.object,
        alias: this.alias || this.object,
        objectId: id,
        plugin: this.plugin,
        params,
      })
        .then((res) => {
          if (res) {
            this.$emit('refreshData');
          }
        });
    },
    /**
     * Emit id of hit clicked to parent
     *
     * @param {string|number} id id of hit
     */
    click(item) {
      this.$emit('click', item.id);
    },
    /**
     * Build list of class for row of table
     *
     * @param {Array} row Row of table
     */
    classRow(row) {
      const classes = [];

      if (row._isFocused) {
        classes.push('primary lighten-5');
      }

      if (this.readStatus && !row._isRead) {
        classes.push('primary lighten-5');
      }
      return classes;
    },
    /**
     * Check if current user has read item
     *
     * @param {Array} readStatus List of users had read item
     */
    isRead(readStatus) {
      return Array.isArray(readStatus)
        && readStatus.indexOf(this.$store.state.auth.currentUserffly4u.id) > -1;
    },
    /**
     * Set read status
     *
     * @param {Object} item Object of item
     * @param {Boolean} read Status of read
     */
    async setReadStatus(item, read) {
      await this.$store
        .dispatch('crud/READ_STATUS', {
          body: {
            customer: item._source.customer,
            application: item._source.application,
            plugin: this.plugin,
            object: this.object,
            alias: this.alias || this.object,
            objectId: item.id,
            read,
          },
        });
    },
    /**
     * Toggle focused status
     *
     * @param {Object} item Object of item
     * @param {Boolean} focus Status of focus
     */
    focus(item, focus) {
      if (focus) {
        this.currentFocused.push(item.id);
      } else {
        this.currentFocused.splice(this.currentFocused.indexOf(item.id), 1);
      }
      this.$emit('focused', this.currentFocused);
    },
    /**
     * Update the preferences of List
     * Merge new preferences with previous
     *
     * @param {Object} preferences object of the page
     */
    updatePrefs(preferences) {
      if (this.keyPref !== 'home') {
        return;
      }
      this.$store.dispatch('auth/SET_SELF', {
        path: this.preferencesPath,
        value: preferences,
      });
    },
    /**
     * Format the data asynchronously (translations, date, etc.)
     */
    async formatContent(rawValue, format) {
      const value = await dataAccess.format(rawValue, format);
      let type;
      /**
       * If format have type
       * The type is added only when format is ready, to prevent error
       */
      if (typeof format.type !== 'undefined') {
        type = format.type.indexOf('link') !== -1 ? 'link' : format.type;
        // For legacy support external link
        this.realType = format.type;
      }

      let label = false;

      if (typeof format.label !== 'undefined') {
        label = this.$t(format.label);
      } else if (this.label !== '') {
        label = this.$t(this.label);
      }

      // If is link check if is externalLink
      if (type === 'link') {
        if (typeof format.isExternal !== 'undefined') {
          this.externalLink = format.isExternal;
        } else { // For legacy support external link
          console.warn('Legacy support: isExternal must be defined in format for \'link\' types');
          if (this.realType === 'linkStreetview') {
            this.externalLink = true;
          }
        }

        if (label === false) {
          label = this.$t('misc.goTo');
        }

        if (!value) {
          type = 'null';
          return null;
        }
        return {
          url: value,
          value,
          label,
        };
      }
      return {
        value,
        label,
      };
    },
  },
};
</script>

<style lang="scss">
  .search .v-text-field__details {
    display: none;
  }
  .table-actions > .action {
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 15px;
    }
  }
</style>
